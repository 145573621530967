import { initializeApp } from "firebase/app";

import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  deleteUser,
  reauthenticateWithCredential,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  doc,
  updateDoc,
  setDoc,
  deleteDoc,
  writeBatch,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCol1rTED9dRWlVU9DChjN_nwjedMUyRlY",
  authDomain: "socials-prod-da2f0.firebaseapp.com",
  projectId: "socials-prod-da2f0",
  storageBucket: "socials-prod-da2f0.appspot.com",
  messagingSenderId: "675578631007",
  appId: "1:675578631007:web:b7bd9a5b668fa557059cea",
  measurementId: "G-20ZVD4G4C6",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const credentials = await signInWithPopup(auth, googleProvider);
    const user = credentials.user;
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

const getProfiles = async (uid) => {
  return await getDocs(collection(db, "users", uid, "profiles"));
};

const deleteBackups = async () => {
  const uid = auth.currentUser.uid;
  try {
    const profiles = await getProfiles();
    profiles.forEach(async (snapshot) => {
      // Update the document to delete all fields
      try {
        const docRef = doc(db, "users", uid, "profiles", snapshot.id);
        await setDoc(docRef, {});
      } catch (error) {
        console.error(
          `Error deleting fields in document with ID ${snapshot.id}: ${error}`
        );
      }
    });
  } catch (error) {
    console.error(
      'Error getting documents from "profiles" subcollection:',
      error
    );
  }
  alert("Any backups saved to this account have been deleted");
};

const deleteCurrentUser = async () => {
  try {
    await deleteUser(auth.currentUser);
  } catch (error) {
    console.error(`couldnt reauth: ${error}, possibly out of date`);
    try {
      const credentials = await signInWithPopup(auth, googleProvider);
      await reauthenticateWithCredential(auth.currentUser, credentials);
      await deleteUser(auth.currentUser);
    } catch (error) {
      alert("Failed to delete your account");
    }
  }
};

export { auth, db, signInWithGoogle, logout, deleteBackups, deleteCurrentUser };
