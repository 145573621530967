import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import { auth, db, logout, deleteBackups, deleteCurrentUser } from "./firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
  }, [user, loading]);

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = () => {
    setOpenDialog(false);
    deleteCurrentUser();
  };

  return (
    <div className="dashboard">
      <Card>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          Logged in as
          <div>{user?.email}</div>
          <Button variant="contained" sx={{ m: 1 }} onClick={deleteBackups}>
            Delete Backups
          </Button>
          <Button variant="contained" sx={{ m: 1 }} onClick={handleClickOpen}>
            Delete Account
          </Button>
          <Button sx={{ m: 1 }} onClick={logout}>
            Logout
          </Button>
        </CardContent>
      </Card>
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">{"Delete account?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Aer you sure you want to delete your account? This cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default Dashboard;
